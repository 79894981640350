import React from 'react';

function App() {
  const showUnderConstruction = process.env.REACT_APP_UNDER_CONSTRUCTION === "true";

  if (showUnderConstruction) {
    return (
      <div>
        <h1>🛠️ Under Construction</h1>
        <p>We're working on something magical... ✨</p>
      </div>
    );
  }

  return (
    <div>

    </div>
  );
}

export default App;
